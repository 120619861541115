.section-container {
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    overflow: hidden;
    background-image: url("../../../Assets/catering_steps.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .icons-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100vw;
    align-items: center;
  }
  
  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .icon {
    margin: 0 10px;
  }
  
  .fea-head{
    font-size: 24px;
    color: white;
    font-family: "rubik";
    font-weight: 700;
    font-size: 2vw;
    
  }
  
 .icon-container p {
    font-size: 1vw;
    width: 15vw;
    font-family: "rubik";
    color: white;
    text-align: center;
    margin-top: 2.4vw;
    font-weight: 500;
    line-height: 1.5vw;
  }
  .btm-btn{
    padding: 0.8vw;
    padding-left: 2vw;
    padding-right: 2vw;
    font-size: 1.3vw;
    color:  #f9a749;
    background-color: white;
    cursor: pointer;
  }
  .btm-btn:hover {
    scale: 1.1;
  }
  
  @media (max-width:500px) {
    .section-container{
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 100vh;
    }
    .icons-row{
      display: flex;
      flex-direction: column;
    }
    .btm-btn{
      font-size: 5vw;
    }
    .fea-head{
      font-size: 5vw;
      margin-right: 3vw;
    }
    .icon-container p{
      font-size: 4vw;
      width: 60vw;
      line-height: 5vw;
    }
    .fas{
      scale: 0.7;
    }
    .icon{
      margin: 0;
    }
  }