.follow-container {
    
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    background-image: url('../../Assets/orange_back.png');
    background-color: #f9a749;
    max-width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* .follow-button {
    width: 450px;
    height: 78px;
    background-color: white;
    color: black;
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 100px;
  } */
    
  .follow-heading{
    font-family: "caveat";
    color: white;
    font-size: 60px;
    font-weight: 600;
  }
  .photo-container {
    display: grid;
    grid-template-columns: repeat(4, 17vw);
    grid-auto-rows: minmax(4em,auto);
    grid-gap: 5px;
    
   
  }
  .photo-cover{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    height: 80vh;
  }
  
  .photo {
    width: 100%;
    height: auto;
  }
  .photo:hover{
    scale: 1.1;
  }
  .follow-wrap{
    width: 80vw;
    margin-bottom: 5vw;
  }
  
  @media (max-width:500px) {
    .follow-container{
      max-width: 100%;
      height: 108vh;
      
   
    }
    .photo-container {
      display: grid;
      grid-template-columns: repeat(2, 38vw);
      grid-auto-rows: minmax(4em,auto);
      grid-gap: 5px;
    }
    .follow-wrap{
      width: 80vw;
    }
  }