
  .card-section{
    width: 100vw;
    height: 100vh;
    background-image: url("../../Assets/orange_back.svg");
    display: flex;
    align-items: center;
    color: white;
    font-family: "rubik";
  }
   .card-wrap{
    display: flex;
    justify-content: space-evenly;
    
    height: 70%;
    
    
    
   }
   .card{
    display: flex;
    
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #F38337;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    width: 20%;
    padding-top: 1.5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-radius: 0.5em;

   }
   .card-heading{
    font-size: 2.5vw;
   }
   .card-paragraph{
    font-size: 1vw;
    font-weight: 500;
    line-height: 1.6vw;
   }
   .card_border{
    width: 22.2vw;
   }
   @media (max-width: 768px) {
   .card-wrap-m{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
   }
   .card{
    display: flex;
    
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #F38337;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    width: 70vw;
    height: 65vh;
    margin-left: 7vw;
    border-radius: 0.5em;

   }
   .card-heading{
    font-size: 10vw;
   }
   .card-paragraph{
    font-size: 4vw;
    font-weight: 500;
    line-height: 5.6vw;
   }
   .card_border{
    width: 80vw;
   }
   .my-next-button{
    color: white;
   }

   }