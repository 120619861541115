.hero-section {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  overflow: hidden;
}

.slideshow {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.slide-image {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  background-size: cover;
  background-position: center;
}

.slide-image.active {
  opacity: 1;
}


.caption {
  /* position: absolute;
  top: 20%;
  border: 2px solid;
  left: 50%;
  transform: translate(-50%, -50%); */
  display: flex;
  /* border: 2px solid; */
  height: 100vh;
  color: #fff;
  text-align: center;
  flex-direction: column;
  align-items: center;
  font-family: "rubik";
  margin-top: 5vw;
}

.caption h1 {
  font-size: 8vw;
  margin-bottom: 1rem;
  font-family: 'caveat';
}

.caption p {
  font-size: 1.2vw;
}

.indicators-res {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 5%;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
}

.indicator-res {
  background-color: #bbb;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.indicator-res.active-res {
  background-color: #fff;
}
.btn-biz{
  background-color: white;
  color:#f9a749;
  font-family: 'rubik';
  font-weight: 500;
  padding: 0.7vw;
  padding-right: 3vw;
  padding-left: 3vw;
  border-radius: 2vw;
  text-align: center;
}
.btn-group{
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100vw;
}
.btn-resHero{
  background-color: white;
  padding: 5vw;
  border-radius: 2vw;
}
.btn-item{
  background-color: white;
  color: black;
  font-family: 'caveat';
  margin-right: 2vw;
  font-size: 120vw;
  font-weight: 800;
  padding: 3vw;
  border-radius: 3vw;
  transition: background-color 0.3s ease-out;
  overflow: hidden;
}


/* .btn-item:hover {
  background-color: orange;
} */

.btn-item:hover::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: orange;
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
}

.btn-item:hover::before {
  transform: translateY(0);
}


@media (max-width:500px) {
  .caption{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
  }
  .caption h1{
    width: 90vw;  
    margin-top: 18vw;
    margin-right: 6vw;
    font-size: 9vw;
    
  }
  .btn-biz{
    width: 20vw;
    padding: 2vw;
    padding-left: 4vw;
    padding-right: 5vw;
   
  }
  .btn-group{
    display: flex;
    flex-direction: column;
    width: 80vw;
    
  }
  /* .btn-item{
    font-size: 43vw;
    
  } */
}