.menu-head{
    display: flex;
    justify-content: space-between;
}
.menu-card-container{
    color: white;
    font-family: 'rubik';
}
.menu-name{
    margin-top: 23px;
    font-size: 1.2vw;
    font-weight: 700;
}
.menu-price{
    margin-top: 20px;
    font-size: 1.4vw;
    font-weight: 700;
}
.menu-parah{
    margin-top: -5px;
    width: 60%;
    font-weight: 400;
    line-height: 1.4vw;
}

@media (max-width: 768px) {
    .menu-name{
        
        font-size: 5vw;
    }
    .menu-price{
        margin-top: 6vw;
        font-size: 4vw;
    }
    .menu-parah{
        font-size: 3vw;
        line-height: 6vw;
    }
}