.aboutus-wrap{
  max-width: 100%;
  overflow-x: hidden;
}
body {
  background-color: #FBAE18;
  overflow-x: hidden;
}
.about-us-container {
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    margin-left: 10%;
    margin-bottom: 10%;
  }
  
  .left-column {
    flex-basis: 50%;
    padding-right: 50px;
  }
  .abthead{
    font-family: 'rubik';
    color: white;
    font-size: 45px;
    font-weight: 700px;
  }
  .abtp{
    font-family: 'rubik';
    color: white;
    font-size: 16px;
    font-weight: 500px;
    line-height: 30px;
    width: 73%;
  }
  .right-column {
    flex-basis: 50%;
  }
  .imgabt{
    scale: 1.2;
  }
  
  .imgabt {
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
    border-radius: 2%;
  }
  
  @media (max-width: 500px) {
    .about-us-container{
      display: flex;
      flex-direction: column;
      margin-top: 20vw;
      z-index: 2;
    }
    .left-column{
      width: 100vw;
      z-index: 2;
    }
    .abthead{
      font-size: 10vw;
      z-index: 2;
    }
    .abtp{
      font-size: 4.5vw;
      line-height: 7vw;
      font-weight: 500;
      z-index: 2;
    }
    .imgabt{
      position: relative;
      bottom: 102vw;
      scale: 4;
      z-index: 0;
    }
  }