.test-container{
    width: 100vw;
    max-width: 100%;
    overflow: hidden;
    background-color: #e4e3e3;
    height: 60vh;
    
}
.test-head{
    text-align: center;
    font-family: 'rubik';
    color:#575757;
    font-size: 3vw;
    
}
.test-wrap{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
}
.slide-wrap{
    width: 80vw;
    height: 80vh;
   display: flex;
    
}
.testimonial-card{
    width: 50vw;
    background-color: white;
    position: relative;
    height: 25vh;
    left: 13vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2vw;
    border-radius: 2vw;
}
.slide-para{
    color: #575757;
    font-family: 'rubik';
    text-align: center;
    line-height: 1.5vw;
    width: 40vw;
    font-size: 0.8vw;
}
.slide-head{
    font-family: 'caveat';
    font-size: 2.4vw;
    
}

.testimonial{

}
@media (max-width:500px) {
    .testimonial-card{
        background-color: rgba(255, 255, 255, 0);
    }
    .test-head{
        font-size: 12vw;
    }
    .slide-para{
        font-size: 5vw;
        line-height: 5vw;
        width: 82vw;
        text-align: center;
    }
    .slide-head{
        margin-top: 20vw;
        font-size: 8vw;
    }
}
