.subscribe-container-ts {
    background-image: url('../../../Assets/catering_footer_image.png');
    background-size: cover;
    background-position: center;
    text-align: center;
    overflow: hidden;
    width: 100vw;
    max-width:100%;
  }
  .subscribe-wrap-ts{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
  }
  .sub-para-ts{
    position: relative;
    top: 4.5vw;
    left: 19vw;
    color: white;
    font-family: "rubik";
    text-align: left;
    font-size: 2em;
    font-weight: 500;
    width: 50%;
  }
  .subscribe-content-ts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 6.75em
  }
  
  .subscribe-input-ts {
    width: 30%;
    padding: 25px;
    font-size: 1.2rem;
    margin-right: 10px;
    border: none;
    
  }
  
  .subscribe-button-ts {
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: white;
    color: black;
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    width: 20%;
  }
  .subscribed-button-ts{
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: rgb(102, 151, 69);
    color: rgb(255, 255, 255);
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    width: 20%;
  }
  
  @media (max-width: 768px) {
    .subscribe-container-ts{
      width: 100vw;
      max-width:100%;
      padding: 0;
    }
    .subscribe-content-ts{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 6vw;
    }
    .subscribe-input-ts{
      width: 70vw;
    }
    .subscribe-button-ts{
      width: 66vw;
      margin-right: 2vw;
    }
    .subscribed-button-ts{
      width: 66vw;
      margin-right: 2vw;
    }
    .sub-para-ts{
      position: relative;
      left: 16vw;
      width: 60vw;
      font-size: 5vw;
    }
  }