.background-whito{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: wheat;
}
.circular-gif{
    scale: 0.5;
}
@media (max-width: 768px) {
    .circular-gif{
        scale: 0.3;
    }
}