.menu-container{
    background-color: rgba(0, 0, 0, 0);
    margin-top: 0;
    width: 100vw;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    font-family: 'rubik';
    max-width: 100%; 
}
.menu_items{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6.2%;
    cursor: pointer;
}
.menu_1{
    display: flex;
    justify-content: space-evenly;
    color: white;
    width: 80vw;
    
    
}


.menu_2{
    display: flex;
    justify-content: space-evenly;
    color: white;
    width: 80vw;  
}
.menu_2_mob{
    display: flex;
    justify-content: space-evenly;
    color: white;
    width: 80vw;  
}
.menu_button{
    background-color: white;
    padding: 5px;
    border-radius: 15px;
    color: black;
    font-size: 1.5vw;
}
.menu_button_2{
    font-size: 1.5vw;
}
.menu_cards_container{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 10vh;
    width: 100vw;
    height: 100%;
    
}
.menu_cards{
    display: grid;
    margin-top: 1px;
    width: 70vw;
    grid-template-columns: repeat(3, 2fr);
    grid-auto-rows: minmax(150px,auto);
    grid-row-gap: 25px;
    grid-column-gap: 75px;
}
.card-head{
    text-align: center;
    color:white;
    font-family: "Caveat";
    font-size: 70px;
}
.beloww_par{
    color: white;
    font-family: "rubik";
    font-weight: 500;
}
.card-info{
    color: white;
    width: 70vw;
    text-align: center;
}
.card-note{
    
    width: 70vw;
    color: white;
    line-height: 2vw;
}

@media (max-width: 500px) {
    .menu_items{
        display: flex;
    }
    .menu_1{
        margin-top: 20vw;
        display: flex;
        width: 80vw;
        flex-wrap: wrap;
        
    }
    .menu_2{
        display: flex;
        font-size: 4vw;
        width: 80vw;
        flex-wrap: wrap;
    }
    .card-head{
        font-size: 10vw;
    }
    .card-info{
        font-size: 4vw;
        text-align: center;
        width: 80vw;
    }
    .menu_cards{
        display: grid;
        margin-top: 1px;
        width: 70vw;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(14vw,auto);
        grid-row-gap: 2vw;
        grid-column-gap: 3vw;
    }
    .card-note{
        font-size: 3vw;
        line-height: 5vw;
        text-align: left;
        width: 80vw;
        text-align: center;
    }
    .menu_button_2{
        font-size: 4vw;
       
    }
    .menu_button{
        font-size: 4vw;
        
    }
   
}