
  .privacy-container{
    background-color: white;
    width: 100vw;
    height: 100%;
    border: 2px solid white;
    font-family: 'rubik';
  }
  .privacy-wrap-w{
    margin-top: 10vw;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .wrap-main{
    display: flex;
    flex-direction: column;
    width: 70vw;
  }
  .privacy-para{
    color:#575757;
    line-height: 1.7vw;
    margin-left: 1vw;
  }
  .privacy-head{
    color:#575757;
  }
  .pri-main-head{
    font-family: 'rubik';
    font-size: 4vw;
    color:#575757;
  }
  .mrgn{
    margin-bottom: 10vw;
  }

  @media (max-width:500px){
    .privacy-para{
      line-height: 6vw;
    }
    .privacy-wrap-w{
      margin-top: 30vw;
    }
    .pri-main-head{
      font-size: 9vw;
    }
    .wrap-main{
      width: 80vw;
    }
    .arrow-priv{
      position: relative;
      top: 1vw;
      left: 2vw;
    }
    .privacy-head{
      font-size: 3vw;
    }
  }

  /* @media (max-width:375px){
    .dropdown-pp{
      margin-left: 21.5vw;
      margin-bottom: -1vw;
      
    }
  } */


































  .collapsible-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .collapsible-header {
    font-size: 24px;
    cursor: pointer;
    margin: 0;
    padding: 20px;
    background-color: #f2f2f2;
    border: none;
  }
  
  .collapsible-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .collapsible-content.collapsed {
    max-height: none;
  }