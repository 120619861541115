.roww{
    margin-top: 10%;
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 10vh;
    justify-content: space-around;
    width: 100vw;
}
.container{
    width: 100vw;
    height: 150vh;
}
.col-left{
    width: 40vw;
    color: white;
}
.col-right{
    background-color: white;
    padding: 1.5%;
    border-radius: 0.7vw;
}
.form-group{
    display: flex;
    flex-direction: column;
    padding-bottom: 1.2vw;
    font-family: "rubik";
    font-weight: 600;
}
.form-control{
    margin-top: 4%;
    width: 18vw;
    height: 3.5vh;
    border-radius: 0.2vw;
    border: 1px solid #a5a5a500;
    background-color: #F9F9F9;
    padding-left: 1vw;
}
.form-control::placeholder{
    padding: 0.4vw;
    color: #979797;
}
.btn-primary{
    margin-top: 1vw;
    width: 18vw;
    background-color: #F9A749;
    border-radius: 5px;
}
.first-option{
    color: #979797;
}
.col-left-head{
    font-size: 50px;
    font-family: "rubik";
}
.col-left-para{
    line-height: 32px;
    width: 35vw;
    font-weight: 500;
    font-size: 17px;
}
.btn-blank{
    background-color: #979797;
    margin-top: 1vw;
    width: 18vw;
    border-radius: 5px;
}
.done-btn{
    background-color: #488346;
    margin-top: 1vw;
    width: 18vw;
    border-radius: 5px;
}
.footru-up{
    margin-top: 5vw;
}

@media (max-width:500px){
    .roww{
        display: flex;
        flex-direction: column;
        padding-left: 5%;
        padding-right: 5%;
        margin: 0;
    }
    .col-left-para{
        display: none;
    }
    .col-right{
        margin-right: 2.5vw;
        width: 82vw;
        border-radius: 5vw;
    }
    .form-group{
        width: 80vw;
    }
    .form-control{
        width: 72vw;
    }
    .col-left-head{
        margin-top: 30vw;
        font-size: 8vw;
        width: 80vw;
    }
    .form-one{
        padding: 3.5vw;
      }
      .btn-primary{
        margin-top: 2vw;
        width: 72vw;
      }
      .btn-blank{
        margin-top: 2vw;
        width: 72vw;
      }
      .re-cap{
        width: 68vw;
      }
      .done-btn{
        margin-top: 2vw;
        width: 72vw;
      }
}