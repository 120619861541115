.footer-container {
    background-color: #FFF9EF;
    
   
    width: 100vw;
    max-width:100%;
  }
  
  /* .logo-column {
    
    
  } */
  
  .logo-image {
    width: 44px;
  }
  .logo-image2 {
    width: 200px;
  }
  
  .address-column {
    
    margin-right: 2vw;
    text-decoration: dashed;
    width: 23vw;
  }
  
  .address-column p {
    text-align: center;
    font-weight: 700;
  }
  
  .location-column {
    flex-basis: 15%;
    margin-right: 40px;
  }
  
  .about-us-column {
    flex-basis: 15%;
    margin-right: 40px;
  }
  
  .socials-column {
    flex-basis: 15%;
  }
  
  .h33 {
    margin-bottom: 20px;
  }
  
  .ull {
    list-style-type: none;
    padding-left: 0;
  }
  
  .lii {
    text-decoration: dashed;
    margin-bottom: 10px;
    line-height: 32px;
  }
  
  .svg_icon {
    margin-right: 0.5vh;
    scale: 1.4;
  }
  .svg_icon_fc{
    margin-right: 0.5vh;
    scale: 1.2;
  }
  .footer-cover{
    padding: 2%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    font-family: "Rubik";
    overflow: hidden;
  }
  .hudd{
    font-weight: 500;
    color:#000000;
  }
  .marll{
    color:#575757;
    font-weight: 300;
  }
  .marllo{
    font-weight: 500;
    color:#575757;
  }
  .huddo{
    color:#575757;
    font-weight: 300;
  }

  @media (max-width:768px) {
    .reverse-pa{
      display: flex;
      flex-direction: column-reverse;
    }
    .footer-container{
      display: flex;
      flex-direction: column-reverse;
    }
    .footer-cover{
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    .div-image{
      display: none;
    }
    .logo-column{
      scale: 4;
      margin-top: 20vw;
      margin-bottom: 20vw;
    }
    .address-column{
      width: 80vw;
    }
    .footer-main-2{
      display: flex;
      justify-content: space-evenly;
      
    }
  }