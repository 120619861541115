.reser-section {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    max-width:100%;
  }
  
  .reser-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../Assets/res_back.png');
    background-size: cover;
    background-position: center center;
    /* filter: blur(2px); */
    z-index: -1;
  }
  
  .reser-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    width: 80%;
    
  }
  
  .reser-heading {
    font-size: 4em;
    color: white;
    font-family: "Rubik";
  }
  
  .reser-paragraph {
    font-size: 1.5rem;
    width: 70%;
    text-align: center;
    color: white;
    font-family: "Rubik";
    margin-bottom: 5em;
  }
  
  .reser-button {
    width: 20%;
    height: 3.6em;
    background-color: white;
    color: black;
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
  }
  .button_fea_catt{
    width: 80%;
    height: 3.6em;
    background-color: white;
    color: black;
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 2vw;
  }
  .button_feaa_catt{
    width: 30vw;
  }
  
  @media (max-width: 768px) {
    .reser-content{
      width: 90%;
    }
    .reser-paragraph{
      width: 90%;
    }
    .reser-heading {
      font-size: 2rem;
    }
    .reser-paragraph {
      font-size: 1rem;
    }
    .reser-button {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      width: 80%;
    }
    .button_feaa_catt{
      margin-left: 1vw;
      width:90vw;
      
    }
    .button_fea_catt{
      width: 90vw;
      height: 18vw;
      color: black;
      font-family: 'rubik';
      font-size: 4.9vw;
      font-weight: 700;
    }
  }
  