.hero-section {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    max-width:100%;
  }
  
  .slideshow-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }
  
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .slide.active {
    opacity: 1;
  }
  
  .hero-content {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
  }
  .hero-contentD {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
  }
  .heading_main{
    font-family: "Caveat";
    font-size: 14vw;
    margin-bottom: -1px;
  }
  .buttons_one{
    display: flex;
    width: 80vw;
    
    justify-content: space-evenly;
    
  }
  .buttons_oneD{
    display: flex;
    width: 50vw;
    justify-content: space-evenly;
    
  }
  .button_one{
    width: 21vw;
    height: 70px;
    background-color: white;
    color: black;
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
  }
  .button_two{
    width: 21vw;
    height: 70px;
    background-color: transparent;
    border: 2px solid white;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
 
  }
   .button_two:hover {
    scale: 1.1;
   }
   
  .button_one:hover {
    scale: 1.1;
  }
 
  .para_main{
    font-family: "Rubik";
    font-size: 20px;
    cursor: pointer;
  }

  .indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 96%;
    width: 100%;
    margin: 0 auto;
    
  }
  
  .indicator {
    background-color: #bbb;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  .right-mar{
    margin-right: 5vw;
  }
  .left-mar{
    margin-left: 5vw;
  }
  
  
  .indicator.active {
    background-color: #ffffff;
  }
  
  @media (max-width:500px) {
    .hero-section {
      position: relative;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
      max-width:100%;
    }
    .buttons_oneD{
      display: flex;
      flex-direction: column;
      width: 85vw;
    }
    .buttons_one{
      display: flex;
      flex-direction: column;
      width: 85vw;
      
    }
    .button_one:hover {
      scale: 1.1;
    }
    .button_two:hover {
      scale: 1.1;
    }
    .right-mar{
      margin-right: 0vw;
    }
    .left-mar{
      margin-left: 0vw;
    }
   
    .reverse-div{
      display: flex;
      flex-direction: column-reverse;
    }
    .reverse-div-2{
      display: flex;
      flex-direction: column-reverse;
    }
    .button_one{
      width: 85vw;
      margin-bottom: 20px;
      height: 70px;
      background-color: white;
      color: black;
      font-family: "Rubik";
      font-size: 20px;
      font-weight: 700;
      cursor: pointer;
    }
    .button_two{
      width: 85vw;
      height: 70px;
      background-color: transparent;
      border: 2px solid white;
      font-size: 20px;
      font-weight: 700;
      cursor: pointer;
    }
    .para_main{
      font-family: "Rubik";
      font-size: 20px;
      cursor: pointer;
      width: 100%;
    }
    .heading_main{
      font-family: "Caveat";
      font-size: 70px;
      margin-bottom: 40px;
    }
  }