   .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding-top: 1vw;
    height: 6vw;
    max-width:100%;
    z-index: 999;
    color: white;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  .navbar-pp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    max-width:100%;
    z-index: 999;
    color: rgb(0, 0, 0);
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }
    .navbar-left{
      display: flex;
      align-items: flex-start;
      height: 3.4vw;
      width: 50vw;
    }
    .navbar-container{
      padding-left: 0.4vw;
      display: flex;
      flex-direction: row;
    }
    .navbar-list-left{
      margin-left: 2vw;
      
      display: flex;
      flex-direction: row;
      width: 30vw;
      height: 2vw;
     
    }
    .listoo{
      font-style: "rubik";
      margin-right: 2vw;
      margin-top: 1.8vw;
      font-size: 1.2vw;
      color: white;
      text-decoration: none;
      font-weight: 700;
    }
    .listoo:hover {
      scale: 1.1;
    }
    .listoo-sc{
      font-style: "rubik";
      margin-right: 2vw;
      font-size: 1.1vw;
      color: rgb(0, 0, 0);
      text-decoration: none;
      font-weight: 700;
      padding-bottom: 2vw;
    }
    .listoo-sc:hover {
      scale: 1.1;
    }

    .navbar-right{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      font-size: 1.1vw;
      margin-right: 1.2vw;
      margin-top: 0.3vw;
      font-family: "rubik";
      font-weight: 600;
      width: 50vw;
      cursor: pointer;
    }
    .loce{
      display: flex;
      justify-content: flex-end;
      width: 20vw;
      
    }
    .loce-arrow{
      scale: 1.5;
      border-radius: 20px;
      margin-left: 0.5em;
      margin-top: 0.1vw;
    }
    .loce-icon{
      scale: 1.4;
      margin-right: 0.5em;
    }
    .dropdown-container{
      display: flex;
      width: 40vw;
      
    }
    .quote-btn{
      background-color: #f9a749;
      padding: 0.6vw;
      position: relative;
      top:1.5vw;
      font-family: 'rubik';
      font-weight: 500;
      color: white;
      font-size: 1.1vw;
      border-radius: 2vw;
      box-shadow: #333;
      margin-top: -0.1vw;
    }
    .quote-btn-white{
      background-color: #f9a749;
      padding: 0.6vw;
      position: relative;
      top:0.8vw;
      font-family: 'rubik';
      font-weight: 500;
      color: white;
      font-size: 1.1vw;
      border-radius: 2vw;
      box-shadow: #333;
      margin-top: -0.1vw;
    }
    .quote-btn:hover {
      scale: 1.1;
    }

    .icon-lft-main{
      width: 5vw;
      padding-top: 0.4vw;
    }
    .icon-lft-main-white{
      width: 3vw;
      padding-top: -0.3vw;
    }


    ul {
      list-style-type: none;
    }
    li{
      list-style-type: none;
    }
    .dropdown{
      position: absolute;
      right:2em ;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      list-style: none;
      text-decoration: none;
      background-color: #fff;
      border-radius: 8px;
      border: 0.5px solid rgba(97, 96, 96, 0.13);
      font-family: "rubik";
      box-shadow: 4px 5px #88888888; 
    }
  .dropdown li {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-decoration: none;
  }
  .dropdown li:hover {
    color: rgb(97, 96, 96);
  }
  /* .locaa{
    width: 100vh;
    display: flex;
    
    cursor: pointer;
  } */
  .navbar-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .navbar-menu li {
    
    font-family: "Rubik";
    
  }
  
  .navbar-menu li:first-child {
    margin-left: 40px;
  }
  
  .navbar-menu li a {
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
  }
  .navbar-menu li a:hover{
    color: #f9a749;
  }
  
  .navbar-white {
    background-color: #fff;
    color: #000000;
    height: 4vw;
    padding-top: 0vw;
  }
  
  .navbar-white .navbar-menu li a {
    color: #333;
  }
  .navbar-white .navbar-menu li a:hover{
    color:#f9a749 ;
  }

  
  @media (max-width: 768px) {
    .icon-lft-main{
      width: 12vw;
    }
    .navbar {
      justify-content: center;
      width: 100vw;
      height: 15vw;
      max-width:100%;
      padding: 10px;
      z-index: 999;
    }
    
    .navbar-list-left{
      color:#000000
    }
    .navbar-list-left{
      display: none;
    }
    .navbar-right{
      display: flex;
      flex-direction: column;
      margin-bottom: 2vw;
      margin-right: 5vw;
    }
    .loce{
      width: 50vw;
      font-size: 4.4vw;
    }
   
    .navbar-menu {
      display: none;
    }
    .dropdown{
      font-size: 3.6vw;
    }
  }
  