.features-section-two {
  display: flex;
  flex-direction: column;
  background-image: url('../../Assets/orange_back.png');
  background-color: #f9a749;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  max-width:100%;
}
  
  .feature-row-two {
    display: flex;
    
    flex-direction: row;
    justify-content: center;
    gap: 280px;
    
  }
  
  .feature-image-twoc{
    width: 40vw;
    z-index: 2;
  }
  
  .feature-content-two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 25%;
    gap:20px;
    margin-left: 10vw;
    
  }
  
  .feature-content-two h2 {
    margin: 0;
  }
  
  button {
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 1px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  .feahead-two{
    font-family: "Rubik";
    font-size: 2vw;
    font-weight: 700;
    line-height: 3vw;
    color: white;
  }
  .feapara-two{
    font-family: "Rubik";
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: white;
  }
    .button_fea-two{
    width: 80%;
    height: 76px;
    background-color: white;
    color: black;
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    }
  button:hover {
    
  }
  
  @media (max-width: 768px) {
    .features-section-two{
      height: 80vh;
    }
   .fea2-m{
    width: 100vw;
    height: 80vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20vw;
   }
   .fea2-bel-m{
    display: flex;
    flex-direction: column;
    align-items: center;
   }
   .fea2head{
    font-family: 'rubik';
    font-size: 7.85vw;
    color: white;
    text-align: left;
   }
   .fea2para{
    font-family: 'rubik';
    color: white;
    font-size: 5vw;
    text-align: center;
    font-weight: 500;
   }
   .button_fea2{
    width: 90vw;
    height: 76px;
    background-color: white;
    color: black;
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
   }
  }
  