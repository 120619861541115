.rightss{
    overflow: hidden;
    
    margin: 0;
    background-color: #575757;
    text-align: center;
    font-size: 11;
    font-family: "Rubik";
    color: white;
    
    width: 100vw;
    max-width: 100% ;
}