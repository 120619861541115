.subscribe-container {
    background-image: url('../../Assets/sub_back.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    overflow: hidden;
    width: 100vw;
    max-width:100%;
  }
  .subscribe-wrap{
    /* background-image: url('../../Assets/Rectangle2.png');
    background-repeat: no-repeat;
    background-position:bottom;
    background-size:89vw;
    background-position-y: 6vw; */
    /* background-color: brown;
    width: 80vw;
    margin-left: 10vw;
    border: 2px solid; */
    display: flex;
    flex-direction: column;
    
    
  }
  .brown-back{
    background-color: rgb(139, 53, 53);
    
  }
 
  .sub-para{
    position: relative;
    top: 8.5vw;
    left: 19.4vw;
    color: white;
    font-family: "rubik";
    text-align: left;
    font-size: 1.5em;
    font-weight: 500;
    width: 50%;
  }
  .subscribe-content {
    position: relative;
    top: 2.5vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 6.75vw
  }
  
  .subscribe-input {
    width: 20%;
    padding: 25px;
    font-size: 1.2rem;
    margin-right: 10px;
    height: 0.2vw;
    border: none;
  }
  
  .subscribe-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: white;
    color: black;
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    width: 20%;
  }
  .subscribed-button{
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: rgb(102, 151, 69);
    color: rgb(255, 255, 255);
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    width: 20%;
  }
  
  @media (max-width: 768px) {
    .subscribe-container{
      width: 100vw;
      max-width:100%;
      height: 65vh;
      padding: 0;
    }
    .subscribe-wrap{
      background-position:bottom;
      background-size:189vh;
     
    }
    .subscribe-content{
      
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding: 6vw;
    }
    .subscribe-input{
      width: 50vw;
      height: 1vw;
    }
    .subscribe-button{
      width: 66vw;
      margin-right: 3vw;
    }
    .subscribed-button{
      width: 66vw;
      margin-right: 3vw;
    }
    .sub-para{
      padding: 0;
      margin-top: 34vw;
      width: 65vw;
      font-size: 4.9vw;
      left: 16vw;
    }
  }