.data-container{
    background-color: white;
    width: 100vw;
    height: 70vh;
    overflow: hidden;
    max-width: 100%;
    font-family: 'rubik';
}
.ress-col-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
}
.ress-head-2{
    margin-top: 3vw;
    color: #575757;
    font-size: 3vw;
}
.ress-items{
    display: flex;
    justify-content: space-evenly;
   
    width: 100vw;
    margin-top: 3vw;
}
.ress-itm-head{
    font-size: 4vw;
    color: #575757;
}
.ress-itm{
    color: #575757;
    font-size: 1.2vw;
    margin-top: -3vw;
}

@media (max-width:500px) {
  
   .ress-items{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .ress-head-2{
      margin-top: 0;
      font-size: 10vw;
      text-align: center;
    }
    .ress-itm-head{
      font-size: 12vw;
      text-align: center;
    }
    .ress-itm{
        font-size: 4vw;
    }
  }