.event-container{
    background-color: white;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    max-width: 100%;
    font-family: 'rubik';
}
.event-head{
    margin-top: 8vw;
    font-size: 3vw;
    color: #575757;
    text-align: center;
    font-weight: 700;

}
.event-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.event-images{
    margin-top: 4vw;
  transition: all 0.3s ease;
  display: grid;
  grid-template-columns: repeat(2, 30vw);
  grid-auto-rows: minmax(5vw,auto);
  grid-gap: 4vw;
  
}
.event-photo{
    height: 10vw;
    margin-right: 1vw;
    margin-bottom: 1vw;
    transition: all 0.3s ease;
}
.event-images{
    
    width: 100vw;
}
.event-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
}
.event-images{
align-items: center;
justify-content: center;
}
/* .event-col-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.event-head-2{
    margin-top: 3vw;
    color: #575757;
    font-size: 3vw;
}
.event-items{
    display: flex;
    justify-content: space-evenly;
   
    width: 100vw;
    margin-top: 3vw;
}
.event-itm-head{
    font-size: 4vw;
    color: #575757;
}
.event-itm{
    color: #575757;
    font-size: 1.2vw;
    margin-top: -3vw;
} */
/* .image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease-out;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 165, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.3s ease-out;
  }
  
  .text {
    color: white;
    font-size: 1.5rem;
    text-align: center;
  }
  
  .image-container:hover {
    transform: scale(1.1);
  }
  
  .image-container:hover .overlay {
    opacity: 1;
  }
  
 */
   .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .image-container {
    position: relative;
    
    overflow: hidden;
    transition: all 0.3s ease-out;
  }
  
  .image {
    display: block;
    width: 30vw;
    object-fit: cover;
    transition: all 0.3s ease-out;
  }
  
  .overlay {
   position: absolute;
   top: 0;
    width: 30vw;
    height: 20vw;
    background-color: rgba(255, 165, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    opacity: 0;
    
  }
  .overlay h1{
    font-family: 'caveat';
    color: white;
    font-size: 4vw;
  }
  .ovr-text{
    font-family: 'rubik';
    color: white;
    font-size: 1vw;
    margin-top: -2vw;
  }
  
 
  
  .image-container:hover {
    transform: scale(1.1);
  }
  
  .image-container:hover .overlay {
    opacity: 1;
  }

  @media (max-width:500px) {
    .event-images{
      transition: all 0.3s ease;
      display: grid;
      grid-template-columns: repeat(1, 80vw);
      grid-auto-rows: minmax(5vw,auto);
      grid-gap: 4vw;
    }
    .image{
      width: 80vw;
    }
    .event-head{
      font-size: 10vw;
    }
    .event-items{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .event-itm-head{
      margin-top: 0;
      font-size: 15vw;
      text-align: center;
    }
    .event-itm{
      font-size: 5vw;
      text-align: center;
    }
    .event-head-2{
      font-size: 15vw;
    }
    .overlay{
      width: 80vw;
      height: 35vw;
    }
    .overlay h1{
      font-size: 10vw;
    }
    .ovr-text{
        font-size: 3vw;
    }
  }
  
  