.features-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../../Assets/orange_back.png');
    background-color: #f9a749;
    
    /* border:2px solid; */
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    max-width:100%;
    
  }
  
  .feature-row {
    display: flex;
   
    flex-direction: row;
    justify-content: center;
    gap: 280px;
    z-index: 0;
   
  }
  
  .feature-image{
    width: 40vw;
    z-index: 2;
  }
  .feature-image2{
    position: absolute;
    top: 20em;
    scale:1;
    z-index: 1;
  }
  .feature-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 7vw;
    width: 30%;
    gap:20px;
    
  }
  
  .feature-content h2 {
    margin: 0;
  }
  
  button {
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 1px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  .feahead{
    font-family: "Rubik";
    font-size: 2vw;
    font-weight: 700;
    line-height: 3vw;
    color: white;
  }
  
  .button_feaa{
   
   width: 30vw;
  }
  .feapara{
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: white;
  }
    .button_fea{
    width: 80%;
    height: 76px;
    background-color: white;
    color: black;
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    }
    .button_fea:hover {
      scale: 1.1;
    }
  button:hover {
    scale: 1.1;
  }
  
  
  @media (max-width: 768px) {
    .feature-row {
      display: flex;
      flex-direction: column-reverse;
      height: 100vh;
      max-width: 100%;
    }
    .features-section{
      height: 80vh;
    }
    
    .feature-content {
      width: 100%;
      max-width: 100%;
      padding-bottom: 200px;
    }
    
    .feature-image{
      position: relative;
      top: -200px;
      width: 10%;
      left: -270px;
    }
    .button_feaa{
      width: 80%;
      margin-right: 25vw;
      
    }
    .fea-img-1{
      
    }
    .fea-m{
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .fea-bel-m{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80vw;
    }
    .fea-abv-m{
      margin-top: 10vw;
    }
    .feapara{
      text-align: center;
    }
    .feahead{
      text-align: left;
      font-size: 6.8vw;
      line-height: 10vw;
      text-align: center;
    }
    .button_fea{
      
      width: 90vw;
    }
  }
  